// LandingPage.js
import React from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import PrivacyPolicy from '../components/privacy-policy/PrivacyPolicy';

const PrivacyPolicyPage = () => {
  return (
    <div>
      <Header />
      <PrivacyPolicy />
      <Footer />
    </div>
  );
};

export default PrivacyPolicyPage;
