import React from 'react';
import './Promotion.css';

const Promotion = () => {
    return (
        <div className="download-app-section">
            <div className='inner-container'>
                <div className='inner-container-content'>
                    <div className="promotion-left-content">
                        <h2 className="section-title">Unlock the Fun! Download Now and Dive into a World of Rewards and Excitement!</h2>
                        <div className="store-badges">
                            {/* Google Play Badge */}
                            <a href="https://play.google.com/store/apps/details?id=com.chellobee.grandquiz" target="_blank" rel="noopener noreferrer">
                                <img src={process.env.PUBLIC_URL + "/images/google-play-badge-white.png"} alt="Google Play" className="store-badge" />
                            </a>
                            {/* App Store Badge */}
                            <a href="https://apps.apple.com/in/app/grandquiz-play-win-rewards/id1541507921" target="_blank" rel="noopener noreferrer">
                                <img src={process.env.PUBLIC_URL + "/images/app-store-badge-white.png"} alt="App Store" className="store-badge" />
                            </a>
                        </div>
                    </div>
                    <div className="promotion-right-content">
                        <img src={process.env.PUBLIC_URL + "/images/promotion-image.png"} alt="App Preview" className="app-preview" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Promotion;
