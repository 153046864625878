// FeaturesSection.js
import React from 'react';
import './Features.css';

const featuresData = [
  {
    icon: 'fas fa-trophy',
    title: 'Quiz Contest',
    description: 'Compete in thrilling quiz contests and showcase your knowledge.',
  },
  {
    icon: 'fas fa-video',
    title: 'Live Quiz',
    description: 'Participate in live quizzes with real-time challenges and excitement.',
  },
  {
    icon: 'fas fa-user-friends',
    title: 'Challenge with Friends',
    description: 'Challenge your friends to quiz battles and see who comes out on top.',
  },
  {
    icon: 'fas fa-users',
    title: 'Multiplayer Quiz',
    description: 'Join multiplayer quizzes and test your knowledge against players worldwide.',
  },
  {
    icon: 'fas fa-puzzle-piece',
    title: 'Puzzle Game',
    description: 'Enjoy fun and brain-teasing puzzles that will keep you entertained for hours.',
  },
  {
    icon: 'fas fa-gallows',
    title: 'Hangman Game',
    src: process.env.PUBLIC_URL + '/images/icon-hangman.png',
    description: 'Guess the word and save the stick figure from being hanged in this classic game.',
  },
  {
    icon: 'fas fa-poll',
    title: 'Polls',
    description: 'Participate in polls and share your opinions on various topics.',
  },
  {
    icon: 'fas fa-pen',
    title: 'Scribble',
    description: 'Unleash your creativity with the Scribble feature. Draw and share your masterpieces.',
  },
  {
    icon: 'fas fa-ellipsis-h',
    title: 'And More...',
    description: 'Explore additional features and surprises waiting for you in GrandQuiz.',
  },
];

const Features = () => {
  return (
    <div className="features-section">
      <div className="features-container">
        <h1>Exciting Features</h1>
        <p>Discover the amazing features that make GrandQuiz a unique and engaging experience.</p>
        <div className="features-content">
          {featuresData.map((feature, index) => (
            <div key={index} className="feature-item">
              {feature.title === 'Hangman Game' ? (
              <img src={feature.src} alt={`${feature.title} Icon`} />
            ) : (
              <i className={feature.icon}></i>
            )}
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;
