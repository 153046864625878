// LandingPage.js
import React from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import FAQ from '../components/faq/FAQ';

const FAQPage = () => {
    return (
        <div>
            <Header />
            <div style={{ paddingTop: 'var(--header-height)' }}><FAQ /></div>
            <Footer />
        </div>
    );
};

export default FAQPage;
