import './App.css';
import { ThemeProvider } from './material3/components/ThemeProvider';
import Container from './material3/components/Container';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';
import FAQPage from './pages/FAQPage';
import FeaturesPage from './pages/FeaturesPage';

function App() {
  return (
    <ThemeProvider>
      <Container>
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/features" element={<FeaturesPage />} />
            {/* Add routes for other pages */}
          </Routes>
        </Router>
      </Container>
    </ThemeProvider>
  );
}

export default App;
