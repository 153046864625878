// LandingPage.js
import React from 'react';
import Header from '../components/header/Header';
import Banner from '../components/banner/Banner';
import Features from '../components/features/Features';
import Footer from '../components/footer/Footer';
import Reviews from '../components/reviews/Reviews';
import FAQ from '../components/faq/FAQ';
import Promotion from '../components/promotion/Promotion';

const LandingPage = () => {
  return (
    <div>
      <Header />
      <Banner />
      <Features />
      <Reviews />
      <Promotion />
      <FAQ />
      <Footer />
    </div>
  );
};

export default LandingPage;
