import React from 'react';
import { useTheme } from './ThemeProvider';
import '../themes/Material.css'

const Button = (props) => {

  const { toggleTheme } = useTheme();

  return (
    // <button className="button" onClick={toggleTheme}>{props.text}</button>
    <button className="button" onClick={props.onClick}>{props.text}</button>
  );
};

export default Button;