// LandingPage.js
import React from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import TermsAndConditions from '../components/terms-and-conditions/TermsAndConditions';

const TermsAndConditionsPage = () => {
  return (
    <div>
      <Header />
      <TermsAndConditions />
      <Footer />
    </div>
  );
};

export default TermsAndConditionsPage;
