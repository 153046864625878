// TermsAndConditionsPage.js
import React from 'react';
import './TermsAndConditions.css'

const TermsAndConditions = () => {
    return (
        <section className='terms-container'>
            <div className='terms-content'>
                <h2>Terms and Conditions</h2>
                <p>
                    <b>Last updated:</b> Sep 10th, 2022.
                </p>

                <h3>
                    INTRODUCTION
                </h3>
                <p>
                    <b>GrandQuiz</b> allows you to access our services using our <b>"application"</b> according to these Terms and conditions. We as <b>GrandQuiz</b> hold all the legal rights to update these terms and conditions from time to time. By accessing this application, you acknowledge that you have understood and comply with these terms and conditions and our Privacy Policy.
                </p>
                <p>
                    In case you do not accept any of these terms, then please don't use our application.
                </p>

                <h3>
                    DEFINITIONS
                </h3>
                <ul>
                    <li><b>"Agreement"</b> denotes to the present Terms and Conditions and the Privacy Policy;</li>
                    <li><b>"User"</b>, <b>"You"</b> and <b>"your"</b> denote the one who is accessing it to require any service from us. User shall embody the corporate, partnership, sole merchandiser, person, company, or association taking services of this application;</li>
                    <li><b>"Customer"</b> denotes who uses our application;</li>
                    <li><b>"We"</b>, <b>"us"</b>, <b>"our"</b> and <b>"Company"</b> references to <b>Chellobee Technologies LLP</b>;</li>
                    <li><b>"application"</b> shall mean <b>GrandQuiz</b> and any successor application of the corporate or any of its affiliates;</li>
                    <li><b>"Customer Account"</b> shall mean associated electronic account opened for the client for availing varied services offered on the application;</li>
                </ul>

                <h3>
                    INTERPRETATION
                </h3>
                <ul>
                    <li>All references to the singular include the plural and the other way around and therefore the word <b>"includes"</b> ought to be construed as <b>"without limitation"</b>.</li>
                    <li>Words importation of any gender shall include all the opposite genders.</li>
                    <li>Reference to any statute, ordinance or alternative law includes all laws and alternative instruments and every consolidation, amendments, re-enactments, or replacements for the nowadays operative.</li>
                    <li>All headings, daring typewriting, and italics (if any) are inserted for convenience of reference solely and don't outline, limit or have an effect on the means or interpretation of the terms of this Agreement.</li>
                </ul>

                <h3>
                    INTRODUCTION AND SCOPE
                </h3>
                <ul>
                    <li><b>Scope:</b> These Terms govern your use of the application and therefore the Services. Except as otherwise such that, these Terms don't apply to Third-Party merchandise or Services, that are legally ruled by their terms of service;</li>
                    <li><b>Eligibility:</b> Certain Service of the application isn't out there to minors below the age of eighteen or to any user's suspended or aloof from the system by us for any reason;</li>
                    <li><b>Electronic Communication:</b> After you use this application or send emails and alternative electronic communications from your desktop or mobile device to us, you act with us electronically. By sending, you conform to receive a reply from us electronically within the same format and you'll keep copies of those communications for your records;</li>
                </ul>

                <h3>
                    USER CONTENT
                </h3>
                <p>
                    <b>Content Responsibility</b>
                </p>
                <p>The application allows users to post feedback on the services. however, you're only liable for the content posted by you. You represent that you just have needed permission to use the content.</p>
                <p>When posting content to the application, please don't post content that:</p>
                <ul>
                    <li>contains rude, profane, abusive, racist, or hateful language or expressions, text, pictures or illustrations that are sexy or in poor style, inflammatory attacks of a private, racial or spiritual nature;</li>
                    <li>is harmful, threatening, derogatory, grossly inflammatory, false, misleading, fraudulent, inaccurate, unfair, contains gross exaggeration or unsupported claims;</li>
                    <li>violates the privacy rights of any third party, is immoderately harmful or offensive to any person or community;</li>
                    <li>discriminate the grounds of race, religion, national origin, gender, age, legal status, sexual orientation or incapacity, or refers to such matters in any manner prohibited by law;</li>
                    <li>violates or not suitably encourages the violation of any municipal, state, federal, or jurisprudence, rule, regulation, or ordinance;</li>
                    <li>uses or tries to use another's account, password, service, or system except as expressly allowable by the Terms of use uploads or transmits viruses or alternative harmful, disruptive, or harmful files;</li>
                    <li>sends perennial messages associated with another user and/or makes disparaging or offensive comments concerning another individual or repeats the previous posting of a similar message underneath multiple emails or subjects;</li>
                    <li>Any submitted content is going to be refused by us. If perennial violations occur, we tend to reserve the right to cancel user access;</li>
                </ul>

                <h3>
                    GENERAL CONDITIONS
                </h3>
                <ul>
                    <li>We never take a guarantee of the accuracy of the information listed on the application;</li>
                    <li>We use open-source frameworks, libraries, and graphics in our application and do not hold any rights or ownership to any open-source contents;</li>
                    <li>We create material changes to those terms of service from time to time, we tend to inform you either conspicuously posting a notice of such changes or via email communication;</li>
                    <li>The application is accessible to you on a restricted, non-exclusive, non-transferrable, non-sub licensable basis, entirely to be utilized about the Service for your personal, non-commercial use, subject to all the Services;</li>
                    <li>Sign In with Apple gives users the option of anonymizing their data, including their email address, when signing in. Users who choose this option have email addresses with the domain privaterelay.appleid.com. We use this anonymised email address to associate with your account details;</li>
                    <li><b>GrandQuiz</b> is a quiz-game application where all the questions are prepared from different sources. We are continuously working to spot the errors and fix them, still, if you find any errors then you can report to us at <a href="mailto: support-grandquiz@chellobee.com">support-grandquiz@chellobee.com</a>;</li>
                </ul>

                <h3>
                    INDEMNITY
                </h3>
                <p>
                    <b>GrandQuiz</b> services are also used for lawful purposes solely. You agree to indemnify and hold <b>Chellobee Technologies LLP</b> harmless from and against all/any claims resulting from your use of our services that damages you or any other party.
                </p>

                <h3>
                    CHILDREN'S INFORMATION (COPPA, 1998)
                </h3>
                <p>
                    We as <b>GrandQuiz</b> take the protection of children as the foremost priority while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
                </p>
                <p>
                    <b>GrandQuiz</b> does not collect any Personal Information from children under the age of 13 (thirteen years). If you think that your child provided any personal information unknowingly on our application, we strongly encourage you to contact us at <a href="mailto: support-grandquiz@chellobee.com">support-grandquiz@chellobee.com</a> immediately and we will do our best efforts to promptly remove such information from our records.
                </p>
                <p>
                    We do not recommend that users under the age of 18 (eighteen years) register on our application "GrandQuiz" without the proper guidance and vigilance of their parents/guardians
                </p>

                <h3>
                    NO RESPONSIBILITY
                </h3>
                <ul>
                    <li>We are not accountable to you for:</li>
                    <li>any losses you suffer as a result of the knowledge you place into our application is inaccurate or incomplete; or</li>
                    <li>any losses you suffer as a result of you can't use our application at any time; or</li>
                    <li>any losses you'll suffer by counting on any statement, postings, or reviews (of our services or that of our partners) on our application; or</li>
                    <li>any unauthorized access or loss of non-public information that's on the far side of our management;</li>
                    <li>any errors “or” information loss due to inescapable things like hacking;</li>
                </ul>

                <h3>
                    THIRD-PARTY LINKS
                </h3>
                <p>
                    The application might comprise links to external or third-party applications (<b>"External apps"</b>). The external links are solely provided by the third-party providers and we do not have access over them. The content of such External apps is made and utilized by others. You'll communicate with the app administrator for those External apps. We are not in charge of the content offered within the link of any External apps and don't provide any representations regarding
                    the content or correctness of the data on such External apps. You ought to take safety measures once you approve downloading files from these applications to safeguards your PC or mobile from viruses and alternative vital programs. If you confirm to access coupled External apps, you are doing so at your own risk.
                </p>

                <h3>
                    PAYMENT AND IN-APP PURCHASE
                </h3>
                <p>
                    <b>GrandQuiz</b> offers In-App purchase options for its users. The app offers a purchase option that allows its users to purchase digital goods such as coins and bees. Users are hereby informed that "the payments will be processed through the Google Play or iTunes app store from which they have originally installed the application on their device."
                </p>
                <p>
                    Also, users can access the applicable "in-app" purchase rules and policies directly from the app stores.
                </p>

                <h3>
                    PERSONAL INFORMATION AND PRIVACY POLICY
                </h3>
                <p>
                    If you access our application, you solely authorize us to store, access your personal information in a manner that we have stated in our privacy policy note.
                </p>

                <h3>
                    COPYRIGHT AND TRADEMARK
                </h3>
                <b>
                    <p>
                        The application might contain material, like computer code, text, graphics, images, designs, sound recordings, audiovisual works, and different materials provided by or on behalf of people (collectively observed because of the "Content"). The Content is also possessed by us or third parties. Unauthorized use of the Content might infringe copyright, trademark, and different laws. you've got no rights in or to the Content, and you'll not take the Content except as allowed below this
                        Agreement. A no different use is allowed without prior written consent from us. You will not transfer, offer license or sub-license, sell, or modify the Content or reproduce display, in public perform, create a spinoff version of, distribute, or otherwise use the Content in any manner for any public or industrial purpose. The utilization or posting of the Content on the other application or in an exceedingly networked laptop setting for any purpose is expressly prohibited.
                    </p>
                    <p>
                        If you infringe any part of this Agreement, your permission to access and/or use the Content and also the application mechanically terminates and you want to straight off destroy any copies you've fabricated from the Content.
                    </p>
                    <p>
                        Our trademarks, service marks, and logos that are displayed on the GrandQuiz application are registered and unregistered by us. The marks of any other company that are located on our application may be trademarks or service marks owned by them. Nothing on the application must be construed as granting, by implication, or otherwise, any license or right to use the Trademarks, without our prior written permission specific for each such use. No Content may be retransferred without our
                        written prior permission.
                    </p>
                </b>

                <h3>
                    MISCELLANEOUS
                </h3>
                <h4>
                    SEVERABILITY
                </h4>
                <p>
                    If any of the provisions of this Terms and Conditions Policy is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that the rest of the Terms will remain in full force and effect.
                </p>
                <h4>
                    TERMINATION
                </h4>
                <p>
                    <b>Term:</b> The Services offered to users may be canceled or terminated by us. We may restrict these Services at any time, with or without prior written notice. We will have no liability to users “or” any third party because of termination of services. Upon termination, all the user subscriptions will be ended.
                    <p>
                        <b>Effect of Termination:</b> On termination of these Terms for any reason, or cancellation or expiration of your Services:
                    </p>
                    <p>
                        (a) We will restrict the Services;
                    </p>
                    <p>
                        (b) you will not be liable for any refunds or usage fees, or any other fees, pro-rata or otherwise;
                    </p>
                    <p>
                        (c) any subscription fees you paid to us will instantly become due and if you wish to reuse subscription services, you have to pay it again, and
                    </p>
                    <p>
                        (d) we may erase your stored data within 60 days.
                    </p>

                    <h3>
                        ENTIRE AGREEMENT
                    </h3>
                    <p>
                        This Agreement constitutes the whole agreement between the parties hereto regarding the topic matter contained during this Agreement.
                    </p>

                    <h3>
                        GOVERNING LAW AND JUDICIAL RECOURSE
                    </h3>
                    <p>
                        These terms and conditions will be ruled by and drafted under the Laws of India without giving effect to any principles of conflicts of law. The Courts of India will have exclusive jurisdiction rights of each dispute arising due to accessing the application.
                    </p>
                    <h3>
                        CONTACT INFORMATION
                    </h3>
                    <p>
                        For any further queries regarding the terms and conditions, feel free to reach us at <a href="mailto: support-grandquiz@chellobee.com">support-grandquiz@chellobee.com</a>.
                    </p>
                </p>
            </div>
        </section>
    );
};

export default TermsAndConditions;
