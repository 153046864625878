// Layout.js
import React from 'react';
import { useTheme } from './ThemeProvider';
import '../../App.css'; // Import global styles
import '../themes/Material.css'

const Container = ({ children }) => {
  const { theme } = useTheme();

  return (
    <div className={`app ${theme}-theme`}>
      {children}
    </div>
  );
};

export default Container;
