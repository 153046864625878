// Footer.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faXTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons"
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className='footer-container'>
                <div className="footer-section">
                    <div className="grandquiz-info">
                        <img src={process.env.PUBLIC_URL + "/logo512.png"} alt="App Icon" />
                        <p>
                            Experience the joy of knowledge with GrandQuiz. Learn, play, and challenge your friends!
                            <br /><br />
                            GrandQuiz is a product of{' '}
                            <a href="https://www.chellobee.com" target="_blank" rel="noopener noreferrer">
                                Chellobee Technologies LLP
                            </a>
                            , a mobile and web development company dedicated to creating innovative and engaging applications.
                        </p>
                    </div>
                </div>

                <div className="footer-section">
                    <h4>Popular Features</h4>
                    <ul>
                        <li><a href="#quiz-contest">Quiz Contest</a></li>
                        <li><a href="#live-quiz">Live Quiz</a></li>
                        <li><a href="#multiplayer-quiz">Multiplayer Quiz</a></li>
                        <li><a href="#hangman-game">Hangman Game</a></li>
                    </ul>
                </div>

                <div className="footer-section">
                    <h4>Quick Links</h4>
                    <ul>
                        <li><a href="/privacy-policy">Privacy Policy</a></li>
                        <li><a href="/terms-and-conditions">Terms and Conditions</a></li>
                    </ul>
                </div>

                <div className="footer-section contact">
                    <h4>Contact</h4>
                    <p>For any enquires and business, please write to us at <a href="mailto:support-grandquiz@chellobee.com">support-grandquiz@chellobee.com</a></p>
                </div>
            </div>

            <div className='footer-section-2'>
                <div className='footer-divider' />
                <div className='footer-social'>
                    <div className="social-icons">
                        <a className='facebook' href="https://facebook.com/GrandQuizChellobee" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook}/></a>
                        <a className='instagram' href="https://www.instagram.com/grandquiz.chellobee/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram}/></a>
                        <a className='twitter' href="https://twitter.com/grand_quiz" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faXTwitter} /></a>
                        <a className='youtube' href="https://www.youtube.com/@grandquiz.chellobee" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faYoutube} /></a>
                    </div>
                    <div className="copyright-text">
                        © {new Date().getFullYear()} All Rights Reserved by Chellobee Technologies LLP
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
