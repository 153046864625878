import React, { useState } from 'react';
import './FAQ.css'

const FAQ = () => {
    const faqData = [
        {
            "question": "How to edit my username?",
            "answer": "Only registered users can edit their username. To edit username, go to your profile and tap on Edit icon to start editing your username and then tap on Done when you are done editing."
        },
        {
            "question": "Can I see the correct answers of the question taken?",
            "answer": "Yes, you can view the correct answers of the question after you submit the answers."
        },
        {
            "question": "How to report incorrect questions or answers?",
            "answer": "After you submit your answer, tap on \"Show Answers\", and then tap on \"Report\" at the top right corner."
        },
        {
            "question": "How many help options are available while playing the quiz?",
            "answer": "A maximum of two help options will be available for each question while playing the quiz."
        },
        {
            "question": "Can I play quizzes on multiple devices with the same account?",
            "answer": "Yes, you can play the quiz on multiple devices with the same account. However deleting the account in any one of the devices will remove the account from all devices."
        },
        {
            "question": "How user level is calculated?",
            "answer": "User level will be calculated based on number of questions correctly answered."
        },
        {
            "question": "Can I change my email or phone number?",
            "answer": "No, you cannot change your email or phone number once you are registered."
        },
        {
            "question": "How to use the referral code received from my friend to collect rewards?",
            "answer": "Go to settings, tap on \"Collect Referral Rewards\", enter the referral code you received from your friend to collect your rewards."
        },
        {
            "question": "How to contact us?",
            "answer": "You can write to us at support-grandquiz@chellobee.com for any queries, feedback and clarifications."
        },
        {
            "question": "How to delete my account?",
            "answer": "Go to settings, tap on \"Delete Account\" to proceed."
        },
        {
            "question": "What are tokens?",
            "answer": "Tokens are rewards with which vouchers can be redeeemed in the vouchers section."
        },
        {
            "question": "How to collect tokens?",
            "answer": "Tokens can be collected by playing Quickz."
        },
        {
            "question": "What are promo codes?",
            "answer": "Promo codes can be used to participate in GrandQuiz promotional contests. Promo codes can be found on our official Facebook and Twitter pages, YouTube channel, and other ad campaigns."
        },
        {
            "question": "How to participate in promotional contest?",
            "answer": "In home screen, tap on the \"Promo\" tab and enter the valid promo code to participate in the promotional contest."
        }
        // Add more FAQ items as needed
    ];

    const [openIndex, setOpenIndex] = useState(null);

    const handleToggle = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <div className="faq-container">
            <div className='faq-content'>
                <h1 class="faq-section-title">Frequently Asked Questions</h1>
                <p class="faq-section-description">Find answers to common questions below:</p>
                {faqData.map((faq, index) => (
                    <div key={index} className={`faq-item ${openIndex === index ? 'open' : ''}`}>
                        <div className="faq-header" onClick={() => handleToggle(index)}>
                            <h4>{faq.question}</h4>
                            {openIndex === index ? (
                                <i className="fas fa-chevron-up"></i>
                            ) : (
                                <i className="fas fa-chevron-down"></i>
                            )}
                        </div>
                        {openIndex === index && (
                            <div className="faq-answer">
                                <p>{faq.answer}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQ;
