// Light Theme
const lightTheme = {
  '--primary-color': '#3D83FB',
  '--primary-color-50': '#e1f5fe',
  '--primary-color-100': '#b3e0ff',
  '--primary-color-200': '#80c7ff',
  '--primary-color-300': '#4da6ff',
  '--primary-color-400': '#2680ff',
  '--primary-color-500': '#3D83FB',
  '--primary-color-600': '#0056b3',
  '--primary-color-700': '#004080',
  '--primary-color-800': '#002b66',
  '--primary-color-900': '#001a40',

  '--secondary-color': '#e15540',
  '--secondary-color-50': '#121518',
  '--secondary-color-100': '#f87b6c',
  '--secondary-color-200': '#fa8a7e',
  '--secondary-color-300': '#fd9a90',
  '--secondary-color-400': '#ffaaa2',
  '--secondary-color-500': '#e15540', /* Keeping the original color as 500 */
  '--secondary-color-600': '#c7462f',
  '--secondary-color-700': '#a8361e',
  '--secondary-color-800': '#89260d',
  '--secondary-color-900': '#6b1500',

  '--background-color': '#FFFFFF',
  '--background-color-50': '#f8f8f8',
  '--background-color-100': '#f5f5f5',
  '--background-color-200': '#eeeeee',
  '--background-color-300': '#e0e0e0',
  '--background-color-400': '#bdbdbd',
  '--background-color-500': '#9e9e9e',
  '--background-color-600': '#757575',
  '--background-color-700': '#616161',
  '--background-color-800': '#424242',
  '--background-color-900': '#212121',

  '--text-color-body': 'rgba(0, 0, 0, 1)', // Body text color for light theme
  '--text-color-title': 'rgba(0, 0, 0, 1)', // Title text color for light theme
  '--text-color-caption': 'rgba(0, 0, 0, 0.7)', // Caption text color for light theme
  '--text-color-headline': 'rgba(0, 0, 0, 1)', // Headline text color for light theme
  '--text-color-disabled': 'rgba(0, 0, 0, 0.5)', // Disabled text color for light theme

  '--divider-color': '#e0e0e0',
};

// Dark Theme
const darkTheme = {
  '--primary-color': '#3D83FB',
  '--primary-color-50': '#e1f5fe',
  '--primary-color-100': '#b3e0ff',
  '--primary-color-200': '#80c7ff',
  '--primary-color-300': '#4da6ff',
  '--primary-color-400': '#2680ff',
  '--primary-color-500': '#3D83FB',
  '--primary-color-600': '#0056b3',
  '--primary-color-700': '#004080',
  '--primary-color-800': '#002b66',
  '--primary-color-900': '#001a40',

  '--secondary-color': '#e15540',
  '--secondary-color-50': '#121518',
  '--secondary-color-100': '#f87b6c',
  '--secondary-color-200': '#fa8a7e',
  '--secondary-color-300': '#fd9a90',
  '--secondary-color-400': '#ffaaa2',
  '--secondary-color-500': '#e15540', /* Keeping the original color as 500 */
  '--secondary-color-600': '#c7462f',
  '--secondary-color-700': '#a8361e',
  '--secondary-color-800': '#89260d',
  '--secondary-color-900': '#6b1500',

  '--background-color': '#0D1013',
  '--background-color-50': '#121518',
  '--background-color-100': '#424242',
  '--background-color-200': '#616161',
  '--background-color-300': '#757575',
  '--background-color-400': '#bdbdbd',
  '--background-color-500': '#9e9e9e',
  '--background-color-600': '#e0e0e0',
  '--background-color-700': '#eeeeee',
  '--background-color-800': '#f5f5f5',
  '--background-color-900': '#fafafa',

  '--text-color-body': 'rgba(255, 255, 255, 1)', // Body text color for dark theme
  '--text-color-title': 'rgba(255, 255, 255, 1)', // Title text color for dark theme
  '--text-color-caption': 'rgba(255, 255, 255, 0.7)', // Caption text color for dark theme
  '--text-color-headline': 'rgba(255, 255, 255, 1)', // Headline text color for dark theme
  '--text-color-disabled': 'rgba(255, 255, 255, 0.5)', // Disabled text color for dark theme

  '--divider-color': '#424242',
};


export { lightTheme, darkTheme };
