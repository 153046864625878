// ThemeProvider.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { lightTheme, darkTheme } from '../themes/Themes';

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('dark');
  const [themeVars, setThemeVars] = useState(darkTheme);
  var currentThemeVars = themeVars;

  useEffect(() => {
   currentThemeVars = theme === 'light' ? lightTheme : darkTheme;

    // Update CSS variables dynamically
    // Object.entries(currentThemeVars).forEach(([property, value]) => {
    //   document.documentElement.style.setProperty(`--${property}`, value);
    // });

    setThemeVars(currentThemeVars);
  }, [theme]);

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    setThemeVars(newTheme === 'light' ? lightTheme : darkTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, themeVars }}>
      <div style={currentThemeVars}>{children}</div>
    </ThemeContext.Provider>
  );
};

const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

export { ThemeProvider, useTheme };
