// PrivacyPolicyPage.js
import React from 'react';
import './PrivacyPolicy.css'

const PrivacyPolicy = () => {
    return (
        <section className='privacy-policy-container'>
            <div className='privacy-policy-content'>
                <h2>Privacy Policy</h2>
                <p>
                    <b>Last updated:</b> Oct 17th, 2021.
                </p>
                <br></br>
                <p>
                    Our Privacy Policy is a part of and browses together with the application's Terms and Conditions. We tend to hold all
                    the legal rights to change this Privacy Policy at any time with or without previous notification to our users.
                </p>

                <p>
                    We respect the privacy of our users and each one who visits our app <b>GrandQuiz</b>. Here, ("we", "us", or "our") we
                    are committed to protecting your information and your right to privacy following the <b>General Data Protection
                        Right(GDPR)</b>. If our users have any concerns, they can contact us at <a
                            href="mailto:support-grandquiz@chellobee.com" target="_blank" rel="noopener noreferrer">support-grandquiz@chellobee.com</a>.
                </p>

                <p>
                    When you visit our application <b>GrandQuiz</b> ("app") and use our services, you trust us along with your
                    information. We mind the privacy of our users seriously. Through this privacy note, we explain what information we
                    collect, how we use it, and what rights you have got in relevance to it. We recommend you to check this note
                    seriously as it is vital. If there is any point for which you do not agree, please discontinue the use of our
                    application and services.
                </p>

                <p>This privacy policy applies to any or all information collected through our application, and/or any connected services,
                    sales, marketing, or events (we visit them jointly during this privacy policy because of the "app").</p>

                <h4>ABOUT US</h4>
                <p><i>
                    At GrandQuiz, we offer a meticulously designed mobile application where we are offering quiz games to our users.
                    All the questions in the game are prepared and collected from various sources.
                </i></p>
                <p><i>
                    We are improving our services continuously.
                </i></p>
                <p>
                    Please read the privacy note carefully.
                </p>

                <h3>
                    WHAT INFORMATION DO WE COLLECT FROM OUR USERS?
                </h3>

                <p>
                    The personal information we ask you to disclose:
                </p>
                <p>
                    We collect the information that you share with us while showing interest in our application or using our services or
                    contact us.
                </p>
                <p>
                    The personal information that we collect depends on the context of your interactions with us and therefore the app,
                    the alternatives you create, and therefore the product and options you use. The non-public information we collect:
                </p>
                <p>
                    <b>Name and Contact Data.</b> Like your name, email, and phone number.
                </p>

                <p>
                    <b>Information we collect automatically</b>
                </p>
                <p>
                    We collect information while using the app. This information doesn't reveal your specific identity (like your name or
                    contact information) however could embrace device and usage information, like your browser, and device details,
                    software version, language, URLs, registered device name, location, etc. If you access our app together with your
                    mobile device, we tend to automatically collect device information (such as your mobile device ID, model, and
                    manufacturer), software, and version details. This information is primarily required to take care of the safety and
                    operation of our app, and for our internal analytics and reportage functions.
                </p>
                <p>
                    We do not use cookies to collect data from you. There may be some external links that use cookies to collect the
                    information. We are not liable "or" responsible for any external links using cookies.
                </p>

                <p>
                    <b>Information we collect from different sources</b>
                </p>
                <p>
                    We could get info regarding you from different sources such as Apple, Google for sign-in purpose.
                </p>

                <h3>
                    HOW DO WE USE YOUR INFORMATION?
                </h3>
                <p>
                    We use your data for these functions in reliance on our legitimate business interests (<b>"Business
                        Purposes"</b>), to enter into or perform a contract with you (<b>"Contractual"</b>). We tend to indicate the precise
                    process grounds we tend to place confidence in next to every purpose listed below.
                </p>
                <p>
                    <b>Use of collected data;</b>
                </p>
                <ul>
                    <li>To send data to you associated with your account, our business functions, and/or for legal reasons. We tend to use
                        your data to send you the merchandise, service, and new feature data and/or data regarding changes to our terms,
                        conditions, and policies.</li>
                    <li>Deliver targeted advertising to you for our Business functions and/or together with your Consent. We have a
                        tendency to use your data to develop and show content and advertising (and work with third parties) tailored to
                        your interests and/or location and to live its effectiveness. We particularly use Appodeal framework to display
                        ads in our application. The further policies of advertising by <b>Appodeal</b> can be found here <a
                            href="https://appodeal.com/privacy-policy/" target="_blank" rel="noopener noreferrer">https://appodeal.com/privacy-policy/</a>.</li>
                    <li>Request Feedback for our Business functions and/or together with your Consent. We tend to use your data to request
                        feedback and to contact you regarding your use of our app.</li>
                    <li>To protect our app for Business functions and/or Legal Reasons. We tend to use your data as a part of our efforts
                        to stay our app safe and secure (for example, for fraud observance and prevention).</li>
                    <li>To alter user-to-user communications together with your consent. we tend to use your data to alter user-to-user
                        communications with every user’s consent.</li>
                    <li>To enforce our terms, conditions, and policies for our business functions and as lawfully needed. We tend to use
                        your data for different Business functions, like knowledge analysis, distinctive usage trends, determining the
                        effectiveness of our promotional campaigns, and evaluating and improving our app, products, services, marketing,
                        and your expertise.</li>
                </ul>

                <h3>
                    WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                </h3>
                <p>
                    We solely share and disclose your information within the following situations:
                </p>
                <ul>
                    <li><b>Compliance with Laws.</b> We tend to disclose your information where we are legally required to do this to comply
                        with applicable law, governmental requests, proceedings, writs, or legal processes, like in response to a writ or a
                        subpoena (including in response to public authorities to fulfil national security or enforcement requirements)</li>
                    <li><b>Vital Interests and Legal Rights.</b> We tend to disclose your information wherever we tend to believe it's
                        necessary to analyze, prevent, or take action concerning potential violations of our policies, suspected fraud,
                        situations involving potential threats to the protection of anyone and smuggled activities, or as proof in legal
                        proceedings.</li>
                    <li><b>Vendors, Consultants, and different Third-Party Service suppliers.</b> We tend to share your knowledge with
                        third-party vendors, service suppliers, contractors, or agents who perform services for us or on our behalf and
                        need access to such information to try to do that job.</li>
                    <li><b>Third-Party Advertisers.</b> We tend to use third-party advertising corporations to serve ads while using the
                        application.</li>
                    <li><b>Business Partners.</b> We tend to share your information with our business partners to supply you with bound
                        merchandise, services, or promotions.</li>
                    <li><b>With your Consent.</b> we tend to disclose your information for other purposes together with your consent.</li>
                </ul>

                <h3>
                    DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                </h3>
                <p>
                    We do not use cookies on our application but there are third-party vendors or some external links that may use
                    cookies and tracking technologies. We do not have any control over them, hence we are not responsible for what
                    information they are using.
                </p>

                <h3>
                    IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
                </h3>
                <p>
                    We will not transfer your personal information to anyone without your sole permission or else it is required
                    legally.
                </p>

                <h3>
                    HOW LONG DO WE KEEP YOUR INFORMATION?
                </h3>
                <p>
                    We will solely keep your data for as long because it is critical for the needs started during this privacy policy
                    unless an extended retention amount is needed or allowable by law (such as tax, accounting, or alternative legal
                    requirements). No purpose during this policy would force us to store your data for an extended period more than 90
                    days (ninety days) past the termination of your account.
                </p>

                <h3>
                    HOW DO WE KEEP YOUR INFORMATION SAFE?
                </h3>
                <p>
                    We have enforced applicable technical and structured security measures designed to shield the protection of any
                    personal information we process. However, please additionally keep in mind that we tend to not guarantee that the
                    internet itself is 100% secure. Although we'll do our greatest to shield your information, the transmission of
                    non-public information to and from our app is at your own risk. You ought to solely access the services at intervals in
                    a secure setting.
                </p>

                <h3>
                    DO WE COLLECT INFORMATION FROM MINORS?
                </h3>
                <p>
                    <b>GrandQuiz</b> targets and is designed for all age groups. We collect the information that is stated above in this
                    policy from our users. We recommend the users below 18 years do not sign up or may not provide personal information
                    without the guidance of the parents or guardians.
                </p>

                <h3>
                    WHAT ARE YOUR PRIVACY RIGHTS?
                </h3>
                <p>
                    <b>Personal information</b>
                </p>
                <p>
                    You may at any time review the data in your account or terminate your account.
                </p>
                <p>
                    Upon your request to delete the data, we will erase the data from our database. Still, some information may be retained
                    in our files to prevent fraud, to exercise any investigation, or to troubleshoot errors.
                </p>
                <h3>
                    GDPR ENTITLEMENT
                </h3>
                <p>
                    <b>EU General Data Protection Regulation (GDPR)</b> rights:
                </p>
                <ul>
                    <li><b>Right to information for processing Personal Information and the rights of the individual.</b></li>
                    <li><b>Right to access the Personal Information that is processed.</b></li>
                    <li><b>Right to erasure ("Right to be forgotten").</b></li>
                    <li><b>Right to rectification.</b></li>
                    <li><b>Right to restriction of the processing.</b></li>
                    <li><b>Right to object (opt-out) to processing.</b></li>
                </ul>
                <p>
                    EU residents can exercise these rights by raising a request directly at <a href="mailto:support-grandquiz@chellobee.com">support-grandquiz@chellobee.com</a>.
                </p>

                <h3>
                    CCPA Privacy Rights
                </h3>
                <p>
                    The <a href="https://leginfo.legislature.ca.gov/faces/codes_displayText.xhtml?division=3.&part=4.&lawCode=CIV&title=1.81.5" target="_blank" rel="noopener noreferrer">California Consumer Privacy Act of 2018</a> (CCPA) gives consumers more control over the personal information that businesses collect about them, and the <a href="https://govt.westlaw.com/calregs/Browse/Home/California/CaliforniaCodeofRegulations?guid=IEB210D8CA2114665A08AF8443F0245AD&originationContext=documenttoc&transitionType=Default&contextData=(sc.Default)">CCPA regulations</a> guide how to implement the law.
                </p>
                <ul>
                    <li><b><a href="https://oag.ca.gov/privacy/ccpa#sectionc" target="_blank" rel="noopener noreferrer">Right to know about personal info that a business collects about them;</a></b></li>
                    <li><b><a href="https://oag.ca.gov/privacy/ccpa#sectione" target="_blank" rel="noopener noreferrer">Right to erase personal information collected from them (with some exceptions);</a></b></li>
                </ul>

                <h3>
                    DO NOT TRACK SIGNALS
                </h3>
                <p>
                    We do not support Do Not Track ("DNT"). Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked. You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.
                </p>

                <h3>
                    YOUR DATA PROTECTION RIGHTS UNDER GENERAL DATA PROTECTION REGULATION (GDPR)
                </h3>
                <p>
                    If you are a resident of the European Economic Area (EEA), you have certain data protection rights. We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.
                </p>
                <p>
                    If you wish to be informed about what Personal Data we hold about you and if you want it to be removed from our systems, please contact us.
                </p>
                <p>
                    In certain circumstances, you have the following data protection rights:
                </p>
                <ul>
                    <li>
                        <b>The right to access, update, or delete the information we have on you.</b>
                        <p>
                            Whenever made possible, you can access, update, or request deletion of your Personal Data directly within your account settings section. If you are unable to perform these actions yourself, please contact us to assist you.
                        </p>
                    </li>
                    <li>
                        <b>The right of rectification.</b>
                        <p>
                            You have the right to have your information rectified if that information is inaccurate or incomplete.
                        </p>
                    </li>
                    <li>
                        <b>The right to object.</b>
                        <p>
                            You have the right to object to our processing of your Personal Data.
                        </p>
                    </li>
                    <li>
                        <b>The right of restriction.</b>
                        <p>
                            You have the right to request that we restrict the processing of your personal information.
                        </p>
                    </li>
                    <li>
                        <b>The right to data portability.</b>
                        <p>
                            You have the right to be provided with a copy of the information we have on you in a structured, machine-readable, and commonly used format.
                        </p>
                    </li>
                    <li>
                        <b>The right to withdraw consent.</b>
                        <p>
                            You also have the right to withdraw your consent at any time where we relied on your consent to process your personal information.
                        </p>
                    </li>
                </ul>
                <p>
                    Please note that we may ask you to verify your identity before responding to such requests.
                </p>

                <h3>
                    PRIVACY POLICY UPDATES
                </h3>
                <p>
                    We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
                </p>
                <p>
                    You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
                </p>

                <h3>
                    CONTACT US
                </h3>
                <p>
                    If you have any questions about this Privacy Policy, please contact us by email: <a href="mailto:support-grandquiz@chellobee.com">support-grandquiz@chellobee.com</a>.
                </p>
            </div>
        </section>
    );
};

export default PrivacyPolicy;
