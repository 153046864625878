// Banner.js
import React from 'react';
import './Banner.css';

const Banner = () => {
    return (
        <div className="banner-container">
            <div className="banner-content">
                <div className="left-content">
                    <h2>
                        Discover, Play, and Win <span className="emphasize-title">Exciting Rewards</span> with GrandQuiz!
                    </h2>
                    <p className="banner-description">
                        Immerse yourself in a world of <span className="emphasize-body">knowledge</span> and <span className="emphasize-body">entertainment</span>. Join GrandQuiz
                        for thrilling quiz contests, live challenges, and more. Compete with <span className="emphasis">friends</span>,
                        showcase your <span className="emphasize-ddoy">skills</span>, and stand a chance to win amazing <span className="emphasize-body">rewards</span> at the end
                        of each quiz!
                    </p>
                    <div className="badges">
                        <a href='https://play.google.com/store/apps/details?id=com.chellobee.grandquiz' target="_blank" rel="noopener noreferrer"><img src={process.env.PUBLIC_URL + "/images/google-play-badge-white.png"} alt="Get it on Google Play" /></a>
                        <a href='https://apps.apple.com/in/app/grandquiz-play-win-rewards/id1541507921' target="_blank" rel="noopener noreferrer" ><img src={process.env.PUBLIC_URL + "/images/app-store-badge-white.png"} alt="Download on the App Store" /></a>
                    </div>
                </div>
                <div className="right-content">
                    <img src={process.env.PUBLIC_URL + "/images/banner-image.png"} alt="GrandQuiz App" />
                </div>
            </div>
        </div>
    );
};

export default Banner;
