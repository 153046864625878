// Reviews.js
import React, { useState, useRef, useEffect } from 'react';
import './Reviews.css';

const Reviews = () => {
    const reviewsList = [
        { text: 'I found a quiz game called GrandQuiz on the app Store. It\'s a free quiz game for trivia addicts and quiz fans of all ages! By answering questions about certain or various categories in the game, players can easily improve their knowledge, abilities, and skills. That\'s not enough, we also earn free Amazon gift card from this. I love this app❤️..', username: 'Jasbir Dabra' },
        { text: 'One of the best ui for any app handsome payouts you can earn and play quiz also . And Team is very nice they take your feedback and even work on it. Must try i would given it 10*stars* if possible.I would recommend you to try this app worth the time . Live chat feature is awesome 😎 . There are daily contest in which you could win 100 rs Amazon voucher. I hope this app remain same but with Better features', username: 'Krish' },
        { text: 'The design, the leveling system, quiz collections, all seems perfect. Very likable quiz app. Well done.', username: 'Sabrina Vasquez' },
        { text: 'A simple, interesting, and pretty effective way to improve general knowledge. Brilliant!', username: 'Jayden Villarreal' },
        { text: 'Very Nice app for earning money 🤑 I have seen very nice tasks and offers and offer wall very hard earning but good app for earning money 💰 I have enjoyed very and my experience is very good i recommend this app for earning Thank you!', username: 'Kushal Mishra' },
        { text: 'I have been playing GrandQuiz for a few weeks and I really enjoy the time while I play it. Very nice!', username: 'Oskar Hoffmann' },
        { text: 'This app is just amazing 🤩🤩🤩🤩🤩 don\'t accept any fake review of the app it\'s just a extreme legit app , even i have redeemed more than 500 rs from this app. If you like this app and earned something then pls do write a review if you can😅. You can earn by playing unlimited quizzes,games etc. ......', username: 'Sanyam Bansal' },
        { text: 'Best game in the world . I won many vouchers and money. My 11 year old child earns 100 rs every day I recommend you to download save this app I will give this 5 rating stars I love this app this is the best app.', username: 'Krish Rawat' },
        { text: 'Very very useful trivia app for mobile users.I like it so much.', username: 'Danielle Mckay' },
        { text: 'This is a legit app 💯. It gives payment to each and every user 😄', username: 'SANIDHYA GAMER' },
        { text: 'It\'s a very good app and i wish all person install in this app and participate in this contest . In this contest i daily income so much money and reward .yes it\'s very good ,better,best other online app . It\'s a better app i say because it\'s give daily contest whose price 100 to 1000 and i win one time 500 rupees so it\'s better app other online app. I recommend to all install app and play more game in this app and win best reward', username: 'pradeep kumar' },
        // Add more reviews as needed
    ];

    const [scrollLeft, setScrollLeft] = useState(0);
    const [maxScroll, setMaxScroll] = useState(0);
    const reviewsRef = useRef(null);

    useEffect(() => {
        const container = reviewsRef.current;
        setMaxScroll(container.scrollWidth - container.clientWidth);
    }, [scrollLeft]);

    const handleScroll = (scrollType) => {
        const container = reviewsRef.current;
        const scrollAmount = 300; // Adjust as needed

        container.scrollTo({
            left: container.scrollLeft + (scrollType === 'left' ? -scrollAmount : scrollAmount),
            behavior: 'smooth',
        });

        setScrollLeft(container.scrollLeft);
    };

    return (
        <div className="reviews-container">
            <div className="reviews-section">
                <div className="reviews-header">
                    <h1>What Our Users Say</h1>
                    <p>Read what some of our users are saying about GrandQuiz</p>
                </div>
                <div className="reviews-list" ref={reviewsRef}>
                    {reviewsList.map((review, index) => (
                        <div key={index} className="review-card">
                            <p className="review-text">{review.text}</p>
                            <p className="review-username">- {review.username}</p>
                        </div>
                    ))}
                </div>
                <div className='scroll-buttons'>
                    <button
                        className="scroll-button"
                        onClick={() => handleScroll('left')}
                        disabled={scrollLeft <= 0}
                    >
                        <i class="fas fa-arrow-left"></i>
                    </button>
                    <button
                        className="scroll-button"
                        onClick={() => handleScroll('right')}
                        disabled={scrollLeft >= maxScroll}
                    >
                        <i class="fas fa-arrow-right"></i>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Reviews;