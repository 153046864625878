// LandingPage.js
import React from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import Features from '../components/features/Features';

const FeaturesPage = () => {
    return (
        <div>
            <Header />
            <div style={{ paddingTop: 'var(--header-height)' }}><Features /></div>
            <Footer />
        </div>
    );
};

export default FeaturesPage;
