// Header.js
import React from 'react';
import { Link } from 'react-router-dom'; // If using React Router
import './Header.css';
import Button from '../../material3/components/Button';

const Header = () => {
  const handlePlayGame = (event) => {
    event.preventDefault();
    console.log('button clicked');
    window.open('https://play-grandquiz.chellobee.com', '_blank', 'noopener,noreferrer');
  };

  return (
    <header className="fixed-header">
      <div className='header-content'>
        <Link to="/"><img className='logo' src={process.env.PUBLIC_URL + '/logo512.png'} alt='GrandQuiz' /></Link>
        <nav>
          <ul className='menu-items'>
            <li><Link to="/features">Features</Link></li>
            <li><Link to="/support">Support</Link></li>
            <li><Link to="/faq">FAQ</Link></li>
          </ul>
          <Button text='Get Vouchers' onClick={handlePlayGame}/>
        </nav>
      </div>
    </header>
  );
};

export default Header;
